<template>
    <div class="home card">
        <img
            class="top-left-ribbon"
            src="../assets/top_left_corner_ribbon.png"
        >
        <h1 class="gold header-text-cursive override-home-card-header">
            Merry Christmas
        </h1>
        <h2 class="gold header-text-cursive override-home-card-header">
            From Andrew & Delta Gorman
        </h2>
        <div
            class="hero-image"
        >
            <img
                src="../assets/christmas_cover_photo.jpeg"
            >
        </div>

        <div class="col-sm-12 mt-2">
            <div class="stack center">
                <router-link
                    :to="{name: 'Details'}"
                    class="christmas-button"
                >
                    Tell me everything!
                </router-link>
                <span class="button-subtext">Est. 5 min read</span>
            </div>
            <div class="stack center">
                <router-link
                    :to="{name: 'Highlights'}"
                    class="christmas-button"
                >
                    Just the highlights, please
                </router-link>
                <span class="button-subtext">Est. 1 min read</span>
            </div>
        </div>
        <img
            class="bottom-right-ribbon"
            src="../assets/bottom_right_corner_ribbon.png"
        >
    </div>
</template>

<script>
    export default {
        name: 'Home',
        mounted() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    };
</script>
<style lang="sass">
    $stupid-font-path: "../assets"
    @import "@/styles/variables.sass"

    .website-container
        .card.home
            display: flex
            flex-direction: column
            align-items: center
            position: relative

            h1.override-home-card-header
                text-align: center
                font-size: 4rem
                margin-bottom: 0

            h2.override-home-card-header
                text-align: center
                margin-top: 0
                font-size: 2rem

            .hero-image
                max-width: 70%
                height: auto
                max-height: 602px

                img
                    border-radius: 15px


    @media(max-width: 975px)
        .card.home
            width: 70%

            h1.override-home-card-header
                font-size: 3rem !important

            h2.override-home-card-header
                font-size: 1.5rem !important

            a.christmas-button
                font-size: 2rem !important
                text-align: center


</style>
